.hamburger {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
}

.hamburger:focus {
  outline: none;
}

.hamburger span {
  display: block;
  position: absolute;
  top: 11px;
  left: 4.5px;
  right: 4.5px;
  height: 2px;
  background: $header-buttons-font-color;
}

.hamburger span::before,
.hamburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $header-buttons-font-color;
  content: "";
}

.hamburger span::before {
  top: -5px;
}

.hamburger span::after {
  bottom: -5px;
}

.hamburger--htla {
}

.hamburger--htla span {
  transition: transform 0.3s;
}

.hamburger--htla span::before {
  transform-origin: top right;
  transition: transform 0.3s, width 0.3s, top 0.3s;
}

.hamburger--htla span::after {
  transform-origin: bottom right;
  transition: transform 0.3s, width 0.3s, bottom 0.3s;
}


.hamburger--htla.is-active span {
  transform: rotate(180deg);
}

.hamburger--htla.is-active span::before,
.hamburger--htla.is-active span::after {
  width: 50%;
}

.hamburger--htla.is-active span::before {
  top: 0;
  transform: translateX(9.5px) translateY(1.5px) rotate(45deg);
}

.hamburger--htla.is-active span::after {
  bottom: 0;
  transform: translateX(9.5px) translateY(-1.6px) rotate(-45deg);
}
