.side-nav {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        display: block;

        //.sub-menu {
        //  display: none;
        //}

        a {
            color: $sidebar-font-color;
            display: block;
            font-size: 14px;
            padding: 12px 12px 12px 30px;
            position: relative;
            text-decoration: none;
        }

        &.active > a {
            color: $ls-color-primary;
        }

        &.has-child > a::before {
            border-color: $sidemenu-arrow-color transparent transparent;
            border-style: solid;
            border-width: 5px 4px 0;
            content: '';
            display: block;
            height: 0;
            margin-top: -2px;
            position: absolute;
            right: 21px;
            top: 50%;
            transform: rotate(-90deg);
            transition: all .2s ease-in-out;
            width: 0;
        }

        &.has-child.open > a::before {
            transform: rotate(0deg);
        }

        //&.has-child.open .sub-menu{
        //  display: block;
        //}

        a:hover {
            background-color: $sidemenu-item-bg-color--hover;
        }

        i {
            width: 30px;
        }
    }

    ul {
        background-color: $sidemenu-submenu-bg;
        padding: 0;
    }

}





