.dashbox {
    background-color: $white;
    border: 1px solid $ls-color-gray--dark;
    border-radius: 4px;
    display: block;
    margin-bottom: 30px;
    padding: 40px;
    position: relative;
    text-align: right;

    i {
        font-size: 30px;
        left: 40px;
        position: absolute;
        top: 53px;
    }

    .title {
        color: $ls-color-black;
        display: block;
        font-size: 24px;
        font-weight: bold;
    }

    .desc {
        color: $ls-color-gray--very-dark;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
    }

    &:active, &:focus, &:hover {
        text-decoration: none;
        background-color: $ls-color-gray--light;
    }
}

// Ecommerce

.dashbox-line-progress {
    padding: 30px;
    text-align: left;

    i {
        right: 30px;
        left: auto;
        top: 30px;
    }

    .progress {
        height: 0.5rem;
        margin-bottom: 0;
    }

    .title {
        margin-bottom: 15px;
        font-size: 26px;
    }

}

.dashbox-circle-progress {
    text-align: left;
    padding: 30px;

    .easy-pie-chart {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translate(0, -50%);
    }
}

@media(min-width: 1200px) and (max-width: 1400px) {
    .dashbox {
        padding: 15px;

        i {
            left: 30px;
            top: 25px;
        }

        &.dashbox-line-progress i {
            right: 15px;
            left: auto;
            top: 15px;
        }
    }

    .dashbox-circle-progress {
        padding: 30px;
    }
}