.table {

    th,
    td {
        font-size: 0.9rem;
    }

    th {
        color: $heading-font-color;
        font-weight: normal;
    }

}

.card-block .table {
    margin-top: -10px;

    th {
        border-top: none;
    }
}
