.site-header {
    background-color: $ls-color-primary;
    height: 60px;
    left: 0;
    padding: 12px 15px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.sidebar-left {
    background: $ls-color-secondary;
    height: 100%;
    left: 0;
    padding: 60px 0 10px;
    position: fixed;
    top: 0;
    width: 240px;
    z-index: 30;
}

.sidebar-body {
    height: 100%;
}

.mobile-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 29;
    background: rgba(48, 75, 88, .5);
    display: none;
}

.layout-default .main-content,
.layout-icon-sidebar .main-content {
    padding: 90px 15px 10px 255px;
    min-height: calc(100% - 39px);
}

.site-footer {
    background-color: $white;
    color: #818a91;
    font-size: 13px;
    font-weight: normal;
    padding: 10px 15px 10px 255px;

    a {
        color: $ls-color-black--light;
    }
}

.page-header {
    margin-bottom: 30px;
    position: relative;

    .breadcrumb {
        background: none;
        padding: 0;
    }

    .page-actions {
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        margin: auto;
    }
}

//Layout Horizontal

@include media-breakpoint-down(sm) {
    .sidebar-left {
        left: -240px;
        transition: left .3s ease;
    }

    .sidebar-open .sidebar-left {
        left: 0;
    }

    .sidebar-open .mobile-menu-overlay {
        display: block;
    }

    .layout-default .main-content,
    .layout-icon-sidebar .main-content {
        padding-left: 15px;
    }

    .site-footer {
        padding-left: 15px;
    }

}
