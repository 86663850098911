.skin-stark {

    // Theme Colors :
    $ls-color-theme: #393F46;

    //HEADER SECTION
    $header-bg-color: $white;
    $header-font-color: $white;
    $header-buttons-bg-color: $ls-color-theme;
    $header-buttons-bg-color--hover: lighten($ls-color-theme, 10%);
    $header-buttons-font-color: $white;

    //SIDEBAR SECTION
    $sidebar-bg-color: $ls-color-theme;
    $sidebar-font-color: $white;
    $sidemenu-item-bg-color--hover: lighten($ls-color-theme, 10%);
    $sidemenu-item-bg-color--active: darken($ls-color-theme, 7%);
    $sidemenu-item-font-color: lighten($ls-color-theme, 55%);
    $sidemenu-item-font-color--active: $white;
    $sidemenu-arrow-color: $ls-color-gray--very-dark;
    $sidemenu-submenu-bg: darken($ls-color-theme, 5%);

    //Horizontal Menu
    $header-bottom-bg: $ls-color-theme;
    $header-menu-item-font-color: darken($white, 10%);
    $header-menu-item-font-color--active: $white;
    $header-menu-item-bg-color--hover: lighten($ls-color-theme, 10%);
    $header-menu-item-bg-color--active: lighten($ls-color-theme, 10%);

    //DROPDOWN
    $dropdown-bg: $ls-color-theme;
    $dropdown-border-color: darken($ls-color-theme, 10%);
    $dropdown-divider-bg: $ls-color-gray--very-dark;
    $dropdown-header-color: $white;
    $dropdown-header-border-color: $ls-color-gray--very-dark;

    //MISC
    $code-bg: $ls-color-gray--light;
    $code-color: $ls-color-theme;

    //HEADER
    .site-header {
        background-color: $header-bg-color;
        border-bottom: 1px solid rgba(230, 230, 230, 0.7);

        .nav-toggle {
            background-color: $header-buttons-bg-color;
            color: $header-buttons-font-color;
        }
        .hamburger span {
            background: $header-buttons-font-color;
        }

        .hamburger span::before,
        .hamburger span::after {
            background-color: $header-buttons-font-color;
        }

        .action-list li {
            a {
                background-color: $header-buttons-bg-color;
                color: $header-buttons-font-color;
            }

            a:hover {
                background-color: $header-buttons-bg-color--hover;
                color: $header-buttons-font-color;
            }
        }

        .dropdown-menu {
            background-color: $ls-color-theme;
        }

        .notification-dropdown {

            .dropdown-header {
                border-bottom: 1px solid $dropdown-header-border-color;
                color: $dropdown-header-color;
            }
        }

        .dropdown-divider {
            background-color: $dropdown-divider-bg;
        }
    }

    //SIDEBAR/NAV

    .sidebar-left {
        background-color: $sidebar-bg-color;

    }

    .side-nav {
        a {
            color: $sidemenu-item-font-color;

            &:hover {
                background-color: $sidemenu-item-bg-color--hover;
            }
        }

        & > li.active > a {
            color: $sidemenu-item-font-color--active;
            border-left: 3px solid $white;
        }

        &.has-child > a::before {
            border-color: $sidemenu-arrow-color transparent transparent;
        }

        ul {
            background-color: $sidemenu-submenu-bg;
        }

        ul li.active > a {
            color: $sidemenu-item-font-color--active;
            background-color: $sidemenu-item-bg-color--active;
        }
    }

    //Horizontal Layout

    .header-bottom {
        background-color: $ls-color-theme;
    }

    .header-nav {

        li a {
            color: $header-menu-item-font-color;

            &:hover {
                background-color: $header-menu-item-bg-color--hover;
                color: $header-menu-item-font-color;
            }
        }

        li.active > a {
            color: $header-menu-item-font-color--active;
            background-color: $header-menu-item-bg-color--active;
            &:hover {
                color: $header-menu-item-font-color--active;
                background-color: $header-menu-item-bg-color--active;
            }
        }
    }

    //Tabs

    .tabs-default {

        .nav-link.active {
            background-color: $ls-color-theme;
            color: $white;

            &:hover, &:active, &:focus {
                background-color: $ls-color-theme;
                color: $white;
            }
        }

        .nav-link:hover {
            border: 1px solid $ls-color-theme;
        }

        .nav-tabs {
            border-bottom: 1px solid $ls-color-theme;
        }
    }

    .tabs-simple {

        .nav-link {
            background-color: transparent;
            color: $ls-color-black--light;

            &:hover, &:active, &:focus {
                border-bottom: 3px solid $ls-color-theme;
                border-color: lighten($ls-color-theme, 30%);
            }

            &.active {
                background-color: transparent;
                border-bottom: 3px solid $ls-color-theme;
                color: $ls-color-black;

                &:hover, &:active, &:focus {
                    border-bottom: 3px solid $ls-color-theme;
                    border-color: $ls-color-theme;
                    color: $ls-color-black;
                }
            }
        }

    }

    .tabs-vertical .nav-tabs {
        border-color: $ls-color-theme;
        li a {
            background-color: $white;
            border-color: transparent;
            border-right-color: $ls-color-theme;

            &:hover, &:active, &:focus {
                background-color: $white;
                border-color: $ls-color-theme;
            }

            &.active {
                background-color: $ls-color-theme;
                color: $white;

                &:hover, &:active, &:focus {
                    background-color: $ls-color-theme;
                    color: $white;
                }
            }
        }
    }

    .tabs-vertical.tabs-simple .nav-tabs {
        border-right: 1px solid #ddd;

        li {
            margin: 0;
        }

        li a {
            background: transparent;
            border-right: 3px solid transparent;
            border-radius: 0;

            &.active {
                border-bottom: 0;
                color: $ls-color-black;
                border-right: 3px solid $ls-color-theme;
                background: transparent;
            }

            &:hover, &:active, &:focus {
                background-color: transparent;
                border-right: 3px solid $ls-color-theme;
                border-bottom: 0;
            }
        }
    }

    //MISC
    code, .code {
        background-color: $code-bg;
        color: $code-color;
    }

    mark, .mark {
        background-color: $ls-color-theme;
        color: $white;
    }

    .todo-container {

        h5 {
            color: $white !important;
        }

        background-color: $ls-color-theme !important;
        color: $white !important;
    }

    //Dropdown
    .dropdown-menu {
        background-color: $dropdown-bg;
        border: 1px solid $dropdown-border-color;
        color: $white;

        .dropdown-header {
            border-bottom: 1px solid $dropdown-header-border-color;
            color: $dropdown-header-color;
        }

        a:hover {
            background-color: $header-buttons-bg-color--hover;
            color: $header-buttons-font-color;
        }
    }

    //Buttons
    .btn-theme {
        @include button-variant($white, $ls-color-theme, $ls-color-theme);
    }

    .btn-theme-outline {
        @include button-outline-variant($ls-color-theme);
    }

    //Forms

    .form-control:focus {
        border: 1px solid $ls-color-theme;
    }

    //SELECT 2
    .select2-container--default .select2-results__option--highlighted[aria-selected] {
        background-color: $ls-color-theme;
        color: $white;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        background-color: $ls-color-theme;
        color: $white;
    }

    .select2-container--default .select2-dropdown {
        border: solid $ls-color-theme 1px;
    }

    .select2-container--default.select2-container--open.select2-container--below .select2-selection {
        border: 1px solid $ls-color-theme;
        border-bottom-color: transparent;
    }

    .select2-container--default .select2-dropdown--below {
        border-top-color: transparent;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
        color: $white;
    }

    //Bootstrap SELECT
    .bootstrap-select .dropdown-menu a {
        color: $header-buttons-font-color;

        &:hover,
        &:active,
        &:focus {
            background-color: $header-buttons-bg-color--hover;
        }
    }
    //DATEPICKER

    .datepicker-dropdown {
        color: $body-color;
        background-color: $white;
        border: 1px solid rgba(0, 0, 0, .15);
    }

    @include media-breakpoint-down(sm) {
        .header-nav .dropdown-menu {
            border: 0 !important;
            background-color: $sidemenu-submenu-bg !important;
        }
    }

}






