.alertify-logs > * {
  padding: 12px 24px;
  color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .2);
  border-radius: 1px
}

.alertify-logs > *, .alertify-logs > .default {
  background: rgba(0, 0, 0, .8)
}

.alertify-logs > .error {
  background: rgba(244, 67, 54, .8)
}

.alertify-logs > .success {
  background: rgba(76, 175, 80, .9)
}

.alertify {
  position: fixed;
  background-color: rgba(0, 0, 0, .3);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}

.alertify.hide {
  opacity: 0;
  pointer-events: none
}

.alertify, .alertify.show {
  box-sizing: border-box;
  transition: all .33s cubic-bezier(.25, .8, .25, 1)
}

.alertify, .alertify * {
  box-sizing: border-box
}

.alertify .dialog {
  padding: 12px
}

.alertify .alert, .alertify .dialog {
  width: 100%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%)
}

.alertify .alert > *, .alertify .dialog > * {
  width: 400px;
  max-width: 95%;
  margin: 0 auto;
  text-align: center;
  padding: 12px;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .14), 0 4px 5px 0 rgba(0, 0, 0, .098), 0 1px 10px 0 rgba(0, 0, 0, .084)
}

.alertify .alert .msg, .alertify .dialog .msg {
  padding: 12px;
  margin-bottom: 12px;
  margin: 0;
  text-align: left
}

.alertify .alert input:not(.form-control), .alertify .dialog input:not(.form-control) {
  margin-bottom: 15px;
  width: 100%;
  font-size: 100%;
  padding: 12px
}

.alertify .alert input:not(.form-control):focus, .alertify .dialog input:not(.form-control):focus {
  outline-offset: -2px
}

.alertify .alert nav, .alertify .dialog nav {
  text-align: right
}

.alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button), .alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button) {
  background: transparent;
  box-sizing: border-box;
  color: rgba(0, 0, 0, .87);
  position: relative;
  outline: 0;
  border: 0;
  display: inline-block;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  padding: 0 6px;
  margin: 6px 8px;
  line-height: 36px;
  min-height: 36px;
  white-space: nowrap;
  min-width: 88px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 2px
}

.alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):active, .alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):hover, .alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):active, .alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):hover {
  background-color: rgba(0, 0, 0, .05)
}

.alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):focus, .alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button):focus {
  border: 1px solid rgba(0, 0, 0, .1)
}

.alertify .alert nav button.btn, .alertify .dialog nav button.btn {
  margin: 6px 4px
}

.alertify-logs {
  position: fixed;
  z-index: 1
}

.alertify-logs.bottom, .alertify-logs:not(.top) {
  bottom: 16px
}

.alertify-logs.left, .alertify-logs:not(.right) {
  left: 16px
}

.alertify-logs.left > *, .alertify-logs:not(.right) > * {
  float: left;
  transform: translateZ(0);
  height: auto
}

.alertify-logs.left > .show, .alertify-logs:not(.right) > .show {
  left: 0
}

.alertify-logs.left > *, .alertify-logs.left > .hide, .alertify-logs:not(.right) > *, .alertify-logs:not(.right) > .hide {
  left: -110%
}

.alertify-logs.right {
  right: 16px
}

.alertify-logs.right > * {
  float: right;
  transform: translateZ(0)
}

.alertify-logs.right > .show {
  right: 0;
  opacity: 1
}

.alertify-logs.right > *, .alertify-logs.right > .hide {
  right: -110%;
  opacity: 0
}

.alertify-logs.top {
  top: 0
}

.alertify-logs > * {
  box-sizing: border-box;
  transition: all .4s cubic-bezier(.25, .8, .25, 1);
  position: relative;
  clear: both;
  backface-visibility: hidden;
  perspective: 1000;
  max-height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none
}

.alertify-logs > .show {
  margin-top: 12px;
  opacity: 1;
  max-height: 1000px;
  padding: 12px;
  pointer-events: auto
}