.layout-icon-sidebar .sidebar-left {
    width: 200px;
    .side-nav {
        li {
            text-align: center;
        }

        li a {
            padding: 15px;
        }

        li i {
            display: block;
            width: 100%;
            margin-bottom: 0.5em;
            font-size: 30px;
        }

    }
}





